<template>
	<div>
		<div>
			<div class="flexX flexcenter flexAround mt20 cardBox pl20 pr20">
				<div class="box-card shou">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日收款</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe612;</span></div>
						<div class="fs20">
							<el-tooltip class="item" effect="dark" placement="top">
								<div class="money">{{data.today_in?data.today_in:0}}</div>
							</el-tooltip>
						</div>
					</div>
				</div>

				<div class="box-card fu">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日代付</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe63b;</span></div>
						<div class="fs20">
							<el-tooltip class="item" effect="dark" placement="top">
								<div class="money">{{data.today_out?data.today_out:0}} </div>
							</el-tooltip>
						</div>
					</div>
				</div>
				
				<div class="box-card success">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日成功率</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe671;</span></div>
						<div class="fs20">{{data.success_ratio?data.success_ratio:0}}%</div>
					</div>
				</div>
				<div class="box-card run">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日利润</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe673;</span></div>
						<div class="fs20">
							<el-tooltip class="item" effect="dark" placement="top">
								<div class="money">{{data.platform_gold?data.platform_gold:0}}</div>
							</el-tooltip>
						</div>
					</div>
				</div>
				<div class="box-card run">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>商户余额</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe673;</span></div>
						<div class="fs20">
							<el-tooltip class="item" effect="dark" placement="top">
								<div class="money">{{data.trader_money?data.trader_money:0}}</div>
							</el-tooltip>
						</div>
					</div>
				</div>
				<div class="box-card run">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>代理余额</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe673;</span></div>
						<div class="fs20">
							<el-tooltip class="item" effect="dark" placement="top">
								<div class="money">{{data.agent_money?data.agent_money:0}}</div>
							</el-tooltip>
						</div>
					</div>
				</div>

				<!-- </div>
			<div class="flexX flexcenter flexAround mt20 cardBox"> -->
				<!-- <div class="box-card ka">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>在线卡数量</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe690;</span></div>
						<div class="fs20">{{data.online_card}}</div>
					</div>
				</div> -->

				<!-- <div class="box-card keshou">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>预计还可收款</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe645;</span></div>
						<div class="fs20">{{data.last_money_in}}</div>
					</div>
				</div> -->
				<!-- <div class="box-card yue">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>当前卡内余额</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe6c2;</span></div>
						<div class="fs20">{{data.card_money}}</div>
					</div>
				</div> -->

			</div>
		</div>
		<el-divider></el-divider>
		<div class="echartsBox flexX flexAround flexcenter">
			<div class="echart" id="main"></div>
			<div class="echart" id="main1"></div>
		</div>
		<div class="echartsBox flexX flexAround flexcenter">
			<div class="echart" id="main2"></div>
			<div class="echart" id="main3"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			return {
				data: {},
				date: [],
				today_in: [],
				today_out:[],
				platform_gold:[],
				success_ratio:[]
			}
		},
		created() {
			this.getData()
			this.$nextTick(() => {
				this.todayIn()
				this.todayOut()
				this.platformGold()
				this.success()
			})
		},
		methods: {
			getData() {
				this.$api.total({}).then(res => {
					if (res.status == 1) {
						this.data = res.data
						this.date = []
						this.today_in = []
						this.today_out = []
						this.platform_gold = []
						this.success_ratio = []
						this.data.twoWeek.forEach(item => {
							this.date.push(item.date)
							this.today_in.push(item.today_in)
							this.today_out.push(item.today_out)
							this.platform_gold.push(item.platform_gold)
							this.success_ratio.push(item.success_ratio)
						})
					}
				})
			},
			todayIn() {
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(document.getElementById('main'));
				// 绘制图表
				myChart.setOption({
					title: {
						text: '收款'
					},
					tooltip: {
						 trigger: 'axis'   // axis   item   none三个值
					},
					xAxis: {
						type: 'category',
						data: this.date,
						axisTick: {
							alignWithLabel: true
						},
						axisLabel: {
							interval: 0,
							rotate: 45, //代表逆时针旋转45度
						}
					},
					yAxis: {
						type: 'value',
						name:'元',
						axisLabel: {
							rotate: 45, //代表逆时针旋转45度
						},
					},
					series: [{
						name: '收款',
						type: 'bar',
						data: this.today_in
					}],



				});

			},
			todayOut() {
				var chartDom = document.getElementById('main1');
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					title: {
						text: '代付'
					},
					tooltip: {
					  trigger: 'axis'   // axis   item   none三个值
					},
					xAxis: {
						type: 'category',
						boundaryGap: true,
						data: this.date,
						axisTick: {
							alignWithLabel: true
						},
						axisLabel: {
							interval: 0,
							rotate: 45, //代表逆时针旋转45度
						}
					},
					yAxis: {
						type: 'value',
						name:'元',
						axisLabel: {
							rotate: 45, //代表逆时针旋转45度
						},
					},
					series: [{
						name: '代付',
						data: this.today_out,
						type: 'line',
						areaStyle: {}
					}]
				};

				option && myChart.setOption(option);
			},
			platformGold(){
				var chartDom = document.getElementById('main2');
				var myChart = echarts.init(chartDom);
				var option;
				option = {
					title: {
						text: '利润'
					},
					tooltip: {
					  trigger: 'axis'   // axis   item   none三个值
					},
				    xAxis: {
				        type: 'category',
				        data:this.date,
						axisTick: {
							alignWithLabel: true
						},
						axisLabel: {
							interval: 0,
							rotate: 45, //代表逆时针旋转45度
						}
				    },
				    yAxis: {
						name:'元',
				        type: 'value',
						axisLabel: {
							rotate: 45, //代表逆时针旋转45度
						},
				    },
				    series: [{
						name: '利润',
				        data: this.platform_gold,
				        type: 'line'
				    }]
				};
				option && myChart.setOption(option);
			},
			success(){
				var chartDom = document.getElementById('main3');
				var myChart = echarts.init(chartDom);
				var option;
				
				// var dataAxis = this.date;
				var data = this.success_ratio;
				var yMax = 500;
				var dataShadow = [];
				
				for (var i = 0; i < data.length; i++) {
				    dataShadow.push(yMax);
				}
				
				option = {
				    title: {
				        text: '成功率',
				        // subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
				    },
					tooltip: {
						 trigger: 'axis'   // axis   item   none三个值
					},
				    xAxis: {
						// type: 'category',
						data:this.date,
						axisTick: {
							alignWithLabel: true
						},
						axisLabel: {
							interval: 0,
							rotate: 45, //代表逆时针旋转45度
						},
				        z: 10
				    },
				    yAxis: {
				        axisLine: {
				            show: false
				        },
				        axisTick: {
				            show: false
				        },
				        axisLabel: {
				            textStyle: {
				                color: '#999'
				            }
				        },						
						type: 'value',
						name:'％',
						axisLabel: {
							rotate: 45, //代表逆时针旋转45度
						},
				    },
				    dataZoom: [
				        {
				            type: 'inside'
				        }
				    ],
				    series: [
				        {
				            type: 'bar',
				            showBackground: true,
				            itemStyle: {
				                color: new echarts.graphic.LinearGradient(
				                    0, 0, 0, 1,
				                    [
				                        {offset: 0, color: '#83bff6'},
				                        {offset: 0.5, color: '#188df0'},
				                        {offset: 1, color: '#188df0'}
				                    ]
				                )
				            },
				            emphasis: {
				                itemStyle: {
				                    color: new echarts.graphic.LinearGradient(
				                        0, 0, 0, 1,
				                        [
				                            {offset: 0, color: '#2378f7'},
				                            {offset: 0.7, color: '#2378f7'},
				                            {offset: 1, color: '#83bff6'}
				                        ]
				                    )
				                }
				            },
				            data: data,
							name: '成功率',
							// data: this.platform_gold,
				        }
				    ]
				};
				
				// Enable data zoom when user click bar.
				var zoomSize = 6;
				myChart.on('click', function (params) {
				    console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
				    myChart.dispatchAction({
				        type: 'dataZoom',
				        startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
				        endValue: dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
				    });
				});
				
				option && myChart.setOption(option);
			}
		},
		watch: {
			today_in(val, old) {
				this.todayIn()
			},
			today_out(val, old) {
				this.todayOut()
			},
			platform_gold(val, old) {
				this.platformGold()
			},
			success_ratio(val, old) {
				this.success()
			}
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.cardBox {


		.text {
			font-size: 14px;
		}

		.clearfix:before,
		.clearfix:after {
			display: table;
			content: "";
			height: 1px;
			width: 100%;
		}

		.clearfix:after {
			clear: both
		}

		.box-card {
			width: 11.75rem;
			box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.5);

			.cardHeader {
				padding: 15px 20px;
				color: $baseColor;
			}

			.cardCon {
				padding: 20px;
				background: rgba(0, 0, 0, 0.2);
				color: $baseColor;
			}

			&.shou {
				background: $success;
			}

			&.fu {
				background: $warning;
			}

			&.run {
				background: $info;
			}

			&.ka {
				background: $warning;
			}

			&.success {
				background: $primary;
			}

			&.keshou {
				background: $success;
			}

			&.yue {
				background: $primary;
			}
		}
	}

	.echartsBox {
		// height: ;
	}

	.echart {
		width: 45%;
		height: 400px;
	}

	// #main{
	// 	width: 500px;
	// 	height: 400px;
	// }
</style>
