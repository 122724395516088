import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets
const {baseURL,proName,payNameImg} = require("@/util/request.js");

import ElementUI from 'element-ui'
import './theme/index.css'
import './style/iconfont.css' 
Vue.use(ElementUI)
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// import './assets/font/fontsize/iconfont.css'
import './style/index.scss' // global css
import local from './util/local'
Vue.prototype.$local = local
import util from './util/util'
Vue.prototype.$util = util
import api from '@/api/api'
Vue.prototype.$api = api
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.prototype.$baseURL= baseURL;
import md5 from 'js-md5';
Vue.prototype.$md5= md5;
import QRCode from 'qrcodejs2'
Vue.prototype.$QRCode= QRCode;

import vueI18n from 'vue-i18n'
Vue.use(vueI18n)
const locale = localStorage.getItem('lang')
const i18n = new vueI18n({
	locale: locale || 'zh',
	messages: {
		'zh': require('/src/assets/lang/zh.js'),
		'en': require('/src/assets/lang/en.js'),
	},
	// silentTranslationWarn: true,
})

let hasSide = 'true'
Vue.prototype.$hasSide = hasSide
Vue.config.productionTip = false


// Vue.prototype.$PayNameImg= 'laike';
// let PayName = '莱克支付'
// Vue.prototype.$PayNameImg= 'laike-IGpay';
// let PayName = 'IGpay'
Vue.prototype.$PayNameImg= payNameImg;
// let PayName = '恒支付'

router.beforeEach((to,from,next)=>{
	document.title = proName
	next()
})
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
