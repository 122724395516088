/**
 * 本地存储操作封装
 * created by 小貂蝉 2020-03-31
 */

//  暴露
export default {
  get(key) {
	  if(window.localStorage.getItem(key) && window.localStorage.getItem(key) != "undefined"){
	  	return JSON.parse(window.localStorage.getItem(key));
	  }else{
	  	return false
	  }
	  
    // console.log(window.localStorage.getItem(key))
    // return JSON.parse(window.localStorage.getItem(key));
  },
  set(key, value) {
    // console.log(value)
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  }
};
