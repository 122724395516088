export const main = {
	language:'English',
	header:{
		'allData':'Data screening',
		'agentCanUseBalance':'Agent available balance',
		'agentFrozenBalance':'Agent frozen balance',
		'traderCanUseBalance':'Merchant available balance',
		'traderFrozenBalance':'Merchant frozen balance',
		'platformLubrication':'Get out profits',
		'accountNumber':'Account number',
		'logOut':'Log out'
	},
	menu:{
		'reportManagement':'Report management',
		'generalStatement':'General statemnt',
		'dsReport':'代收报表',
		'dfReport':'代付报表',
		'agentIncome':'Agent income',
		'merchantIncome':'Merchant income',
		'merchantRate':'Merchant Rate',
		'collectionMethod':'Collection method',
		'expenditureStatement':'Expenditure statement',
		'passagewayStatement':'Passageway Statement',
		'passagewayManagement':'Passageway management',
		'currencyManagement':'Currency management',
		'collectionMethodManagement':'Collection method management',
		'accountManagement':'Account management',
		'agentManagement':'Agent management',
		'merchantManagement':'Merchant management',
		'merchantMoneyChange':'Merchant account change statement',
		'agentMoneyChange':'Agent account change statement',
		'orderManagement':'Order management',
		'payOrderManagement':'Payment order management',
		'systemSettings':'System settings',
		'passagewaySystemManagement':'Passageway system management',
		'collectionOrder':'Collection order',
		'abOrder':'Abnormal orders',
		'offlineDepositOrder':'Offline deposit order',
		'paymentOrder':'Payment Order',
		'platformLubricationOrder':'Platform lubrication order',
		'agentLubricationOrder':'Agent lubrication order',
		'supplementOrder':'Supplement order',
		'changePassword':'Change password',
		'revokeOrder':'Revoke order',
		'supplementRecord':'Supplement record',
		'supplementOrderPay':'Supplementary payment order',
		'blacklistManagement':'Blacklist management',
		'transferPlatformProfit':'Transfer platform profit',
		'platformLubrication':'Platform lubrication',
		'userManagement':'User management',
		'roleManagement':'Role management',
		'operationLog':'Operation log',
		'allData':'Data screening',
		'telegram':'Telegram management',
		'botManagement': 'Robot configuration',
		'merchantChatManagement': 'Merchant Group',
		'passagewayChatManagement': 'Channel group',
		'noticeManagement': 'Group message',
	},

	reportMenu:{
		'theDayBefore':'The day before',
		'theNextDay':'The next Day',
		'collectionMethod':'Collection method',
		'query':'Query',
		'serialNumber':'Serial number',
		'agent':'Agent',
		'agentRemarks':'Agent remarks',
		'numberOfMerchants':'Number of merchants',
		'submit':'Submit',
		'success':'Success',
		'successRate':'Success rate',
		'numberOfOrder':'Number of order',
		'amount':'Amount',
		'passageway':'Passageway',
		'platform':'Platform',
		'business':'Business',
		'totalAmount':'TotalAmont',
		'to':'to',
		'pleaseChoose':'Please choose',
		'PleaseEnterTheAgentAccount':'Enter the agent account',
		'PleaseEnterTheBusinessAccount':'Enter the business account',
	},
	passagewayMenu:{
		'add':'Newly added',
		'addCurrency':'Add currency',
		'currencyName':'Currency name',
		'currencyAbbreviation':'Currency abbreviation',
		'exchangeRate':'Exchange rate(That is, the conversion rate of 1usdt)',
		'operation':'Operation',
		'setExchangeRate':'Set exchange rate',
		'collectionMethod':'Collection method',
		'paymentMethod':'Payment method',
		'state':'State',
		'enable':'Enable',
		'disable':'Disable',
		'passagewayName':'Passageway name',
		'balance':'Balance',
		'mainSwitch':'Main switch',
		'collectionSwitch':'Collection switch',
		'paymentSwitch':'Payment switch',
		'open':'open',
		'close':'close',
		'commissionCollection':'Commission collection',
		'commissionPayment':'Commission payment',
		'setLimits':'Set limits',
		'rate':'Rate',
		'minimum':'Minimum',
		'maximum':'Maximum',
		'singleCost':'Single cost',
		'singleLimit':'Single limit',
	},
	accountMenu:{
		'add':'Newly added',
		'agentName':'Agent name',
		'remarks':'Remarks',
		'totalAmount':'Total amount',
		'balanceOfEachAccount':'Balance of each account',
		'frozenBalance':'Frozen balance',
		'frozenBalanceOfEachAccount':'Frozen balance of each account',
		'state':'State',
		'function':'Function',
		'enable':'Enable',
		'disable':'Disable',
		'edit':'Edit',
		'commissionCollection':'Commission collection',
		'commissionPayment':'Commission payment',
		'offlineDepositHandlingFee':'Offline deposit handling fee',
		'resetAgentPassword':'Reset agent password',
		'addSubordinateAgent':'Add subordinate agent',
		'total':'Total',
		'serialNumber':'Serial number',
		'superiorAccount':'Superior account',
		'merchantName':'Merchant name',
		'merchantAccount':'Merchant account(Login)',
		'merchantNumber':'Merchant number',
		'resetMerchantPassword':'Reset merchant password',
		'resetGoogleVerification':'reset google verification',
		'setLimits':'Set limits',
		'freezeMerchantBalance':'Freeze merchant balance',
		'unfreezeMerchantBalance':'Unfreeze merchant balance',
		'enableMerchant':'Enable merchant',
		'disableMerchant':'Disable merchant',
		'passagewayConfiguration':'Passageway configuration',
		'passagewayProportionalConfiguration':'Passageway proportional configuration',
		'setPaymentPassword':'Set payment password',
		'actionLog':'Action log',
		'whiteList':'White list',
		'balanceRetentionCap':'Balance retention cap',
		'nameMatchingPattern':'Name matching pattern',
		'bindMerchantCurrency':'Bind merchant currency',
		'totalOfCurrencyPage':'Total of currency page',
		'noData':'No data',
	},
	orderMenu:{
		'theDayBefore':'The day before',
		'theNextDay':'The next day',
		'pleaseEnterTheOrderNumber':'Please enter the order number',
		'pleaseEnterTransactionNo':'Please enter transaction no',
		'pleaseEnterTheMerchantNumber':'Please enter the merchant number',
		'pleaseSelect':'Please select',
		'pleaseEnterTheMerchantName':'Please enter the merchant name',
		'pleaseEnterTheNameOfTheBankCardHolder': 'Please enter the name of the bank card holder',
		'pleaseEnterTheSubmittedAmount':'Please enter the submitted amount',
		'pleaseEnterIp':'Please enter IP',
		'PleaseEnterThePayerName':'Please enter the payer name',
		'to':'to',
		'pleaseSelectCollectionMethod':'Please select collection method',
		'pleaseSelectPassageway':'Please select passageway',
		'pleaseSelectState':'Please select state',
		'query':'Query',
		'exportExcel':'Export Excel',
		'orderNumber':'Order number',
		'transactionNo':'Transaction no',
		'merchant':'Merchant',
		'passageway':'Passageway',
		'collectionMethod':'Collection method',
		'paymentMethod':'Payment method',
		'orderAmount':'Order amount',
		'actualAmount':'Actual amount',
		'currency':'Currency',
		'orderState':'Order state',
		'commission':'Commission',
		'ActualArrival':'Actual arrival',
		'submissionTime':'Submission time',
		'nameOfPayer':'Name of payer',
		'process':'Process',
		'processingTime':'Processing time',
		'pleaseSelectCurrency':'Please select currency',
		'sendCallback':'Send callback',
		'supplement':'Supplement',
		'handlingRate':'Handling rate',
		'singleHandlingCharge':'Single handling charge',
		'transactionInformation':'Transaction information',
		'userType':'User type',
		'userInformation':'User information',
		'paymentInformation':'Payment information',
		'remarks':'Remarks',
		'confirmOrder':'Confirm order',
		'closeOrder':'Close order',
		'replenishmentConfirmationOrder':'Replenishment confirmation order',
		'prompt':'If there is no order in the system, the order will be supplemented here',
		'numberOfSupplementaryOrders':'Number of supplementary orders',
		'numberOfAdditionalOrders':'Number of additional orders',
		'numberOfCancellations':'Number of cancellations',
		'supplementAmount':'Supplement amount',
		'additionalOrderAmount':'Additional order amount',
		'cancellationAmount':'Cancellation amount',
		'orderType':'Order type',
		'reasonForReplenishment':'Reason for Replenishment',
		'allSupplements':'All supplements',
		'supplementaryOrder':'Supplementary order',
		'additionalOrder':'Additional order',
		'cancelTheOrder':'Cancel the order',
		'orderSucceeded':'Order succeeded',
		'waitingForPayment':'Waiting for payment',
		'orderClosed':'Order closed',
		'function':'Function',
		'handler':'Handler',
		'batchOrderNotSubmittedForProcessing':'Batch order not submitted for processing',
		'merchantNumber':'Merchant number',
		'merchantName':'Merchant name',
		'pleaseEnterTheAmount':'Please enter the amount',
		'statisticsToday':'Statistics today',
	},
	systemMenu:{
		'changePassword':'Change password',
		'originalPassword':'Original password',
		'pleaseEnterOriginalPassword':'Please enter original password',
		'newPassword':'New password',
		'pleaseEnterNewPassword':'Please enter new password',
		'confirmPassword':'Confirm password',
		'pleaseEnterTheNewPasswordAgain':'Please enter the new password again',
		'googleVerificationCode':'Google verification code',
		'pleaseEntergoogleVerificationCode':'Please enter google verification code',
		'submit':'Submit',
		'newlyAdded':'Newly added',
		'serialNumber':'Serial number',
		'userName':'User nama',
		'lastLoginTime':'Last login time',
		'role':'Role',
		'function':'Function',
		'resetUserPassword':'Reset user password',
		'resetGoogleVerificationCode':'ResetGoogle verification code',
		'deleteUser':'Delete user',
		'setRole':'Set role',
		'deleteRole':'Delete role',
		'setPermissions':'Set permissions',
		'noData':'No data',
	},
	common:{
		'confirm':'Are you sure to perform this operation?',
		'cancelConfirm':'This operation has been canceled',
		'successConfirm':'Successful execution',
		'prompt':'Prompt',
		'submit':'Submit',
		'cancel':'Cancel',
	},
	title: 'test',
}